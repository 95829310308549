import { getConstantAll } from 'commons/js/constants'

export default function convertConstantToEPProviderFormat (constants = getConstantAll()) {
    return {
        keycloak: constants.KEYCLOAK,
        tradename: constants.TRADENAME,
        country: constants.COUNTRY,
        currencyFormats: parseCurrentFormats(constants),
        facebookAppId: constants.FACEBOOK_APP_ID,
        googleRecaptchaSitekey: constants.GOOGLE_RECAPTCHA_SITEKEY,
        googleAppId: constants.GOOGLE_APP_ID,
        firebaseAppId: constants.FIREBASE_APP_ID,
        firebaseApiKey: constants.FIREBASE_API_KEY,
        firebaseAuthDomain: constants.FIREBASE_AUTH_DOMAIN,
        firebaseProjectId: constants.FIREBASE_PROJECT_ID,
        firebaseStorageBucket: constants.FIREBASE_STORAGE_BUCKET,
        firebaseMessagingSenderId: constants.FIREBASE_MESSAGING_SENDER_ID,
        flavor: constants.FLAVOR,
    }
}

function parseCurrentFormats (constants) {
    const rawCurrencyFormats = constants.CURRENCY_FORMAT
    return Object.entries(rawCurrencyFormats).map(entry => {
        return {
            symbol: entry[1].symbol,
            code: entry[0],
            fractionDigits: entry[1].fractionDigits,
            format: entry[1].format,
        }
    })
}
